import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { IDatapoint, ISeries } from "../../Types/types";
import Chart from 'chart.js/auto'
import _ from "lodash";

export type GameVizProps = {
  series: ISeries[];
};

const GameViz: React.FC<GameVizProps> = ({
  series,
}) => {

  (async function () {

    try {
      var existing_chart = Chart.getChart('rankCanvas')
      if (existing_chart) existing_chart.destroy();
      //console.log('trying to destory then redraw chart');
    } catch (e) {
      console.log('chart does not exist yet to destroy');
    }

    const acquisitionsElement = document.getElementById('rankCanvas') as HTMLCanvasElement;
    const xx = series.flatMap(d => d.data).map(x => x?.y || 0);
    const maxxx = Math.floor((_.max(xx) || 1) * 1.1);
    if (acquisitionsElement) {
      new Chart(
        acquisitionsElement,
        {
          type: 'line',
          options: {
            aspectRatio: 1,
            animation: {
              duration: 0
            },
            scales: {
              x: {
                max: 10,
              },
              y: {
                max: maxxx,
                min: 0
              },
            },
            plugins: {
              legend: {
                display: true,
                labels: {
                  color: 'black'
                }
              }
            }
          },

          data: {
            labels: [3, 4, 5, 6, 7, 8, 9, 10],
            datasets: series.map(s => ({
              label: s.title,
              data: s.data.map(d => d.y),
            }))
          }
        }
      );
    }

  })();

  return (
    <Grid item xs={12}>
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid black",
          // height: "100px",
          //width: "100px",
          padding: "0px",
          marginTop: '4px',
          marginBottom: '4px'
        }}
      >
        <div><canvas width="200px" height="200px" id="rankCanvas"></canvas></div>
      </Grid>
    </Grid>
  );
};

export default GameViz;
