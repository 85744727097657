import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import _ from "lodash";
import axios from "axios";
import {
  ICard,
  IPlayerTurn,
  IHand,
  IPlayResponse,
  IPlayRequest,
  IChat,
  IProfile,
} from "../../Types/types";
import Game from "../Game";
import { makeId } from "../../Types/util";

type ShellProps = {
  token: string;
  session: string;
  playerEmail: string;
  turn: IPlayerTurn;
  chat: IChat | undefined;
  profile: IProfile | undefined;
  reset: boolean;
  fetchSessions: () => void;
  refreshGame: () => void;
  nextGame: () => void;
  logout: () => void;
  challengeWord: (word: ICard[], susPlayer: string, round: number) => void;
  acknowledgeChallenge: (id: string, isWord: boolean) => void;
  cancelChallenge: (id: string) => void;
  dismissChallenge: (id: string) => void;
  sendChat: (message: string) => void;
  hardRefreshGame: () => void;
};

const Shell: React.FC<ShellProps> = ({
  token,
  session,
  playerEmail,
  turn,
  chat,
  profile,
  reset,
  fetchSessions,
  refreshGame,
  nextGame,
  logout,
  challengeWord,
  acknowledgeChallenge,
  cancelChallenge,
  dismissChallenge,
  sendChat,
  hardRefreshGame,
}) => {
  const [avatars, setAvatars] = useState(turn.avatars);
  const [gameSummary, setGameSummary] = useState(turn.gameSummary);
  const [roundScores, setRoundScores] = useState(turn.roundScores);
  const [gameResult, setGameResult] = useState(turn.gameResult);
  const [runningSummary, setRunningSummary] = useState(turn.runningSummary);
  const [challenges, setChallenges] = useState(turn.challenges);
  const [roundSummaries, setRoundSummaries] = useState(turn.roundSummaries);
  const [gameSeries, setGameSeries] = useState(turn.gameSeries);
  const [hand, setHand] = useState<IHand | undefined>(turn.hand);
  const [title, setTitle] = useState(turn.title);
  const [nextRoundStats, setNextRoundStats] = useState(turn.nextRoundStats);
  const [roundAmount, setRoundAmount] = useState(turn.roundAmount);
  const [turnId, setTurnId] = useState(turn.turnId);
  const [pickupCard, setPickupCard] = useState<ICard | undefined>(turn.pickup);
  const [additionalCard, setAdditionalCard] = useState<ICard | undefined>(turn.additionalCard);
  const [hasDrawn, setHasDrawn] = useState<boolean>(turn.hasDrawn);
  const [canPass, setCanPass] = useState<boolean>(turn.canPass);
  const [isOut, setIsOut] = useState<boolean>(turn.isOut);
  const [isMyTurn, setIsMyTurn] = useState<boolean>(turn.isPlayersTurn);
  const [isRoundOver, setIsRoundOver] = useState<boolean>(turn.isRoundOver);
  const [isGameOver, setIsGameOver] = useState<boolean>(turn.isGameOver);
  const [isGamePlayerConfirmed, setIsGamePlayerConfirmed] = useState<boolean>(
    turn.isGamePlayerConfirmed
  );
  const [isGameFullyConfirmed, setIsGameFullyConfirmed] = useState<boolean>(
    turn.isGameFullyConfirmed
  );
  const [isRoundConfirmable, setIsRoundConfirmable] = useState<boolean>(
    turn.isRoundConfirmable
  );
  const [isRoundPlayerConfirmed, setIsRoundPlayerConfirmed] = useState<boolean>(
    turn.isRoundPlayerConfirmed
  );
  const [isRoundFullyConfirmed, setIsRoundFullyConfirmed] = useState<boolean>(
    turn.isRoundFullyConfirmed
  );
  const [waitingOn, setWaitingOn] = useState<string | undefined>(
    turn.waitingOn
  );
  const [outPlayers, setOutPlayers] = useState(turn.outPlayers);

  const [prompt, setPrompt] = useState<string>(
    turn.prompt
  );

  useEffect(() => {
    setHand(turn.hand);
    setTitle(turn.title);
    setNextRoundStats(turn.nextRoundStats)
    setRoundAmount(turn.roundAmount);
    setTurnId(turn.turnId);
    setPickupCard(turn.pickup);
    setAdditionalCard(turn.additionalCard);
    setHasDrawn(turn.hasDrawn);
    setGameSummary(turn.gameSummary);
    setAvatars(turn.avatars);
    setRoundScores(turn.roundScores);
    setGameResult(turn.gameResult);
    setRunningSummary(turn.runningSummary);
    setRoundSummaries(turn.roundSummaries);
    setChallenges(turn.challenges);
    setGameSeries(turn.gameSeries);
    setIsMyTurn(turn.isPlayersTurn);
    setWaitingOn(turn.waitingOn);
    setCanPass(turn.canPass);
    setIsOut(turn.isOut);
    setIsGameOver(turn.isGameOver);
    setIsGamePlayerConfirmed(turn.isGamePlayerConfirmed);
    setIsGameFullyConfirmed(turn.isGameFullyConfirmed);
    setIsRoundPlayerConfirmed(turn.isRoundPlayerConfirmed);
    setIsRoundFullyConfirmed(turn.isRoundFullyConfirmed);
    setIsRoundConfirmable(turn.isRoundFullyConfirmed);
    setIsRoundOver(turn.isRoundOver);
    setOutPlayers(turn.outPlayers);
    setPrompt(turn.prompt);
  }, [reset]);

  useEffect(() => {
    setHand(turn.hand);
    setTitle(turn.title);
    setNextRoundStats(turn.nextRoundStats);
    setRoundAmount(turn.roundAmount);
    setTurnId(turn.turnId);
    setPickupCard(turn.pickup);
    setAdditionalCard(turn.additionalCard);
    setHasDrawn(turn.hasDrawn);
    setAvatars(turn.avatars);
    setGameSummary(turn.gameSummary);
    setRoundScores(turn.roundScores);
    setRoundSummaries(turn.roundSummaries);
    setGameResult(turn.gameResult);
    setRunningSummary(turn.runningSummary);
    setChallenges(turn.challenges);
    setGameSeries(turn.gameSeries);
    setIsMyTurn(turn.isPlayersTurn);
    setWaitingOn(turn.waitingOn);
    setCanPass(turn.canPass);
    setIsOut(turn.isOut);
    setIsGameOver(turn.isGameOver);
    setIsGameFullyConfirmed(turn.isGameFullyConfirmed);
    setIsGamePlayerConfirmed(turn.isGamePlayerConfirmed);
    setIsGameFullyConfirmed(turn.isGameFullyConfirmed);
    setIsRoundPlayerConfirmed(turn.isRoundPlayerConfirmed);
    setIsRoundFullyConfirmed(turn.isRoundFullyConfirmed);
    setIsRoundConfirmable(turn.isRoundConfirmable);
    setIsRoundOver(turn.isRoundOver);
    setOutPlayers(turn.outPlayers);
    setPrompt(turn.prompt);
  }, [turn]);

  const play = (play: IPlayRequest) => {
    const url = "/" + session + "/play";
    axios
      .post<IPlayResponse>(
        url,
        play,
        {
          headers: {
            "x-access-token": token,
            "x-player-email": playerEmail,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          refreshGame();
          fetchSessions();
        } else if (res.status === 401) {
          console.error(res.data.toString());
          logout();
        } else {
          console.error(res.data.toString());
        }
      }).catch(err => {
        console.error(err.toString());
      });
  };

  const drawCardFromDeck = () => {
    const url = "/" + session + "/draw";
    axios
      .post<ICard>(
        url,
        {
          playerEmail,
        },
        {
          headers: {
            "x-access-token": token,
            "x-player-email": playerEmail,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          var card = res.data as ICard;
          card = { ...card, id: makeId() };

          setAdditionalCard(card);
          setHasDrawn(true);
          setPickupCard(undefined);
          setHand(hand ? { cards: hand.cards.concat(card) } : undefined);

        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const pickupCardFromPile = () => {
    const url = "/" + session + "/pickup";
    axios
      .post<ICard>(
        url,
        {
          playerEmail,
        },
        {
          headers: {
            "x-access-token": token,
            "x-player-email": playerEmail,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAdditionalCard(pickupCard);
          setHasDrawn(true);
          setPickupCard(undefined);
          setHand(hand && pickupCard ? { cards: hand.cards.concat(pickupCard) } : undefined);
        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const confirmRound = (player: string) => {
    const url = "/" + session + "/confirmRound";
    axios
      .post<ICard>(
        url,
        {
          player,
        },
        {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          refreshGame();
        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const confirmGame = (player: string) => {
    const url = "/" + session + "/confirmGame";
    axios
      .post<ICard>(
        url,
        {
          player,
        },
        {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          refreshGame();
        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const doCanfirmRound = () => {
    confirmRound(playerEmail);
  };
  const doConfirmGame = () => {
    confirmGame(playerEmail);
  };

  return (
    <Grid
      container
      style={{
        width: "100%",
        border: "0px solid #0f1919",
        background: "transparent",
      }}
    >
      <Grid item xs={12}>
        <Game
          turnId={turnId}
          round={roundAmount}
          hand={(hand?.cards || [])}
          isPlayersTurn={isMyTurn}
          isGameOver={isGameOver}
          isRoundOver={isRoundOver}
          isGameFullyConfirmed={isGameFullyConfirmed}
          isGamePlayerConfirmed={isGamePlayerConfirmed}
          isRoundPlayerConfirmed={isRoundPlayerConfirmed}
          isRoundFullyConfirmed={isRoundFullyConfirmed}
          isRoundConfirmable={isRoundConfirmable}
          pickup={pickupCard}
          additionalCard={additionalCard}
          hasDrawn={hasDrawn}
          canPass={canPass}
          isOut={isOut}
          avatars={avatars || []}
          gameSummary={gameSummary}
          roundScores={roundScores}
          gameResult={gameResult}
          runningSummary={runningSummary}
          challenges={challenges}
          roundSummaries={roundSummaries}
          gameviz={gameSeries}
          nextRoundStats={nextRoundStats}
          title={title}
          waitingOn={waitingOn}
          outPlayers={outPlayers}
          reset={reset}
          chat={chat}
          profile={profile}
          play={play}
          drawCardFromDeck={drawCardFromDeck}
          pickupCardFromPile={pickupCardFromPile}
          confirmRound={doCanfirmRound}
          confirmGame={doConfirmGame}
          nextGame={nextGame}
          challengeWord={challengeWord}
          acknowledgeChallenge={acknowledgeChallenge}
          cancelChallenge={cancelChallenge}
          dismissChallenge={dismissChallenge}
          sendChat={sendChat}
          hardRefreshGame={hardRefreshGame}
          prompt={prompt}
        />
      </Grid>

    </Grid>
  );
};

export default Shell;
