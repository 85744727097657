import React, { useState } from "react";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { IFriend, IPlayerAccomplishment, IProfile, IProfileSaveRequest } from "../../Types/types";
import EditIcon from '@mui/icons-material/Edit';
import ProfileFriends from "../ProfileFriends";
import ChatChips from "../ChatChips";
import CheckIcon from '@mui/icons-material/Check';
import Stats from "../Stats";
type ProfileProps = {
  profile: IProfile;
  saveProfile: (profile: IProfileSaveRequest) => void;
};

const Profile: React.FC<ProfileProps> = ({
  profile: initProfile,
  saveProfile,
}) => {


  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [profile, setProfile] = useState<IProfile>(initProfile);
  const [friends, setFriends] = useState<IFriend[]>(profile.friends);
  const [chatchips, setChatChips] = useState<string[]>(profile.chatChips);
  const [modifiedFriends, setModifiedFriends] = useState<string[]>([]);
  const [plusChatChips, setPlusChatChips] = useState<string[]>([]);
  const [minusChatChips, setMinusChatChips] = useState<string[]>([]);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [editName, setEditName] = useState(profile.name);
  const [isEditing, setIsEditing] = useState<boolean>(false);


  const doEditName = () => {
    if (editName && editName.length > 0) {
      setIsDirty(true);
    }
  };
  // const removeFriend = (friend: IFriend) => {
  //   setIsDirty(true);
  // };

  const addFriend = (friend: string) => {
    const newFriends = _.uniq(modifiedFriends.concat(friend));
    setModifiedFriends(newFriends)
    setIsDirty(true);
    const allFriends = _.uniq(friends.concat({ playerName: profile.name + "'s friend", identity: friend }));
    setFriends(friends.concat(allFriends))
  };

  const removeChatChip = (chatchip: string) => {
    setMinusChatChips(minusChatChips.concat([chatchip]));
    setIsDirty(true);
    const newChips = profile.chatChips.filter(x => x !== chatchip);
    setChatChips(chatchips.concat(newChips));
  };

  const addChatChip = (chatchip: string) => {
    setPlusChatChips(plusChatChips.concat([chatchip]));
    setIsDirty(true);
    setChatChips(chatchips.concat([chatchip]));
  };

  const doSaveProfile = () => {

    const minLengthRegex = /^.{3,}$/;
    const maaxLengthRegex = /^.{0,9}$/;

    var newName: string | undefined = undefined;

    if ((profile.name !== editName)) {

      if (!minLengthRegex.test(editName)) setMessage("your new name must be between 3 and 10 characters");
      else if (!maaxLengthRegex.test(editName)) setMessage("your new name must be between 3 and 10 characters");
      else {
        setMessage(undefined);
        newName = editName;
      }
    }
    if (!message) {
      const delta: IProfileSaveRequest = {
        newName: newName,
        addedFriends: modifiedFriends,
        addedChatChips: plusChatChips,
        removedChatChips: minusChatChips,
      }
      saveProfile(delta);
      setIsDirty(false);
    }
  };

  const doRestartTutorial = () => {
    const delta: IProfileSaveRequest = {
      showTutorial: true,
    }
    saveProfile(delta);
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        background: "transparent",
        paddingTop: "0px",
        border: "0px solid #0f1919",
      }}
    >
      {isDirty && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "red",
              width: "100%",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            don't forget to save your changes
          </Typography>
        </Grid>
      )}
      {message && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "red",
              width: "100%",
              fontWeight: 350,
            }}
          >
            {message}
          </Typography>
        </Grid>
      )}

      {!isEditing && (

        <Grid
          container
          item
          xs={12}
          style={{
            margin: "0px",
          }}
        >
          <Grid display="flex" justifyContent="center" container item xs={10} style={{ width: "100%" }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              {profile.name}
            </Typography>
          </Grid>

          <Grid display="flex" justifyContent="center" container item xs={2} style={{ width: "100%" }}>
            <IconButton
              style={{
                background: "transparent",
                color: "black",
                width: "30px",
                height: "30px",
                border: "0px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
              onClick={(e) => {
                setIsEditing(true);
              }}
            >
              <EditIcon />
            </IconButton>


          </Grid>
        </Grid>
      )}

      {isEditing && (
        <Grid
          container
          item
          xs={10}
          style={{
            margin: "4px",
            marginTop: "20px",
          }}
        >
          <Grid container item xs={12} >
            <Typography
              variant="h2"
              style={{
                color: "#0f1919",
                margin: "4px",
                marginBottom: "12px",
              }}
            >
              your name
            </Typography>
          </Grid>

          <Grid display="flex" justifyContent="center" container item xs={10} style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="change your name"
              value={editName}
              style={{
                background: "white",
                width: "100%",
                padding: "0px",
              }}
              onChange={(event) => {
                setEditName(event.target.value)
                setIsDirty(true);
              }}
              variant="outlined"
            />
          </Grid>

          <Grid display="flex" justifyContent="center" container item xs={2} style={{ width: "100%" }}>
            <IconButton
              style={{
                background: "green",
                color: "white",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
              onClick={(e) => {
                setIsEditing(false);
                setIsDirty(true);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}

      {profile.stats && (
        <Grid
          container
          item
          xs={12}
          style={{
            margin: "4px",
          }}
        >

          <Typography
            variant="h1"
            style={{
              color: "#0f1919",
              margin: "4px",
            }}
          >
            stats
          </Typography>

          <Stats stats={profile.stats}></Stats>
        </Grid>
      )}

      {profile.accomplishments && profile.accomplishments.length > 0 && (
        <Grid
          container
          item
          xs={12}
          style={{
            margin: "4px",
          }}
        >

          <Typography
            variant="h1"
            style={{
              color: "#0f1919",
              margin: "4px",
            }}
          >
            accomplishments
          </Typography>

          {profile.accomplishments && profile.accomplishments.map((accomplishment: IPlayerAccomplishment, i) => (
            <Grid
              container
              item xs={12}
              justifyContent={"center"}
              style={{
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
              <Typography
                align="left"
                variant="h1"
                style={{
                  color: "#245501",
                  fontWeight: 500,
                }}
              >
                {accomplishment.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        style={{
          margin: "4px",
        }}
      >
        <Grid container item xs={12}>
          <ProfileFriends
            friends={friends}
            addFriend={addFriend}
          // removeFriend={removeFriend}
          ></ProfileFriends>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{
          margin: "4px",
        }}
      >
        <Grid container item xs={12}>
          <ChatChips
            chatChips={profile.chatChips}
            addChatChip={addChatChip}
            removeChatChip={removeChatChip}
          ></ChatChips>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="center"
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginBottom: "0px",
          marginTop: "4px",
        }}
      >
        <Button
          disabled={!isDirty}
          style={{
            background: isDirty ? "green" : "gray",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            width: "90%",
          }}

          onClick={(e) => {
            if (isDirty) {
              setIsDirty(false);
              doSaveProfile();
            }
          }}
        >
          save
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: "0px",
          marginBottom: "4px",
        }}
      >
        {/* <Grid container item xs={12} >
          <Typography
            variant="h2"
            style={{
              color: "#0f1919",
              margin: "4px",
              marginBottom: "12px",
            }}
          >
            tutorial
          </Typography>
        </Grid> */}
        <Grid container item
          justifyContent="center"
          xs={12} >
          <Button
            style={{
              background: "green",
              width: "90%",
              height: "38.5px",
              color: "white",
              border: "1px solid #0f1919",
              padding: "8px",
              marginTop: "24px",
            }}

            onClick={(e) => {
              doRestartTutorial();

            }}
          >
            redo tutorial
          </Button>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default Profile;
