
import React from "react";
import { useDrag, DragSourceHookSpec, DragSourceMonitor } from "react-dnd";
import Card from "../Card";
import { ICard, ISpace } from "../../Types/types";

export default function DndCard(props: {
    index: number;
    space: ISpace | undefined,
    play: (id: number, card: ICard) => void
}) {
    const doPlay = (card: ICard) => {
        props.play(props.space?.id || 0, card);
    };

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: {
            type: "card",
            id: props.space?.id,
        },
        collect: (monitor: DragSourceMonitor<{ type: string }, unknown>) => (
            {
                isDragging: !!monitor.isDragging(),
            }),
    });

    return (
        <>
            <div
                ref={drag}
                style={{
                    opacity: isDragging ? 0.9 : 1,
                }}
            >
                {props.space && props.space.card && (
                    <Card key={"k-hcssq" + props.space.id} card={props.space.card} play={doPlay} isSmall={false} isInPlay={!props.space.isInPlay} />
                )}
                {!props.space && (
                    <div
                        key={"k-hc" + props.index}
                        style={{
                            background: "white",
                            width: "43px",
                            height: "70px",
                        }}
                    >&nbsp;
                    </div>
                )}
            </div>
        </>
    );
}

