import _ from "lodash";
import { ICard, ISpace } from "./types";
import { v4 as uuidv4 } from "uuid";

export const compareCards = (c1: ICard | undefined, c2: ICard) => {
  if (!c1) return false;
  return c1.letters === c2.letters && c1.value === c2.value;
};

export const compareHands = (h1: ICard[], h2: ICard[]) => {
  if (h1.length !== h2.length) return false;
  for (var i = 0; i < h1.length; i++) {
    if (!compareCards(h1[i], h2[i])) return false;
  }
  return true;
};

export function makeId() {
  const guid = uuidv4();
  return guid
    .substring(guid.length - 9, guid.length - 1)
    .toUpperCase();
}

export const removeCardsFromListOfCards = function (cards: ICard[], toRemove: ICard[]) {
  const array = _.cloneDeep(cards);
  for (let i = 0; i < toRemove.length; i++) {
    if (array.map(c => c.letters).includes(toRemove[i].letters)) {
      const ci = array.map(c => c.letters).indexOf(toRemove[i].letters);
      array.splice(ci, 1);
    }
  }
  return array;
};

export const removeCardsFromListOfSpaces = function (spaces: ISpace[], toRemove: ICard[]) {
  const array = _.cloneDeep(spaces);
  for (let i = 0; i < toRemove.length; i++) {
    if (array.map(s => s.card && s.card.letters).includes(toRemove[i].letters)) {
      const ci = array.map(x => x.card && x.card.letters).indexOf(toRemove[i].letters);
      array.splice(ci, 1);
    }
  }
  return array;
};

export const findCommonSpaces = function (spaces: ISpace[], cards: ICard[]) {
  const array = spaces.map(x => x);
  for (let i = 0; i < cards.length; i++) {
    if (!array.map(s => s.card && s.card.letters).includes(cards[i].letters)) {
      const ci = array.map(x => x.card && x.card.letters).indexOf(cards[i].letters);
      if (ci !== -1)
        array.splice(ci, 1);
    }
  }
  return array;
};

export function makeSpaces(cards: ICard[], spaceBuffer: number): ISpace[] {
  const spaces: ISpace[] = [];
  for (var i = 0; i < spaceBuffer; i++) {
    spaces.push({ id: i + 1, card: undefined, isInPlay: true });
  };
  cards.forEach((c, j) => {
    const ind = j + 1 + spaceBuffer;
    spaces.push({ id: ind, card: c, isInPlay: true });
  });
  for (var k = 0; k < spaceBuffer; k++) {
    spaces.push({ id: k + 1 + spaceBuffer + cards.length, card: undefined, isInPlay: true });
  };
  return spaces;
}