import React, { useEffect, useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import {
  IAvatar,
  ICard,
  IChallengeWordSummary,
  IChat,
  IGameResult,
  IGameSummary,
  IPlayRequest,
  IPlayerRoundScore,
  IPlayerRoundStat,
  IProfile,
  IRunningSessionSummary,
  ISeries,
} from "../../Types/types";
import GameScore from "../GameScore";
import GameSummary from "../GameSummary";
import RoundSummary from "../RoundSummary";
import _ from "lodash";
import Challenge from "../Challenges";
import Play from "../Play";
import Avatars from "../Avatars";
import Chat from "../Chat";
import StatsRow from "../RoundStatsRow";
import EndGameRoundSummary from "../EndGameRoundSummary"; import GameViz from "../HistorySummary";
import GameResult from "../GameResult";


type GameProps = {
  turnId: string;
  round: number;
  isPlayersTurn: boolean;
  isGameOver: boolean;
  isGameFullyConfirmed: boolean;
  isGamePlayerConfirmed: boolean;
  isRoundConfirmable: boolean;
  isRoundOver: boolean;
  isRoundPlayerConfirmed: boolean;
  isRoundFullyConfirmed: boolean;
  canPass: boolean;
  isOut: boolean;
  avatars: IAvatar[];
  gameSummary: IGameSummary;
  roundScores: IPlayerRoundScore[];
  roundSummaries: IPlayerRoundScore[][] | undefined;
  gameResult: IGameResult | undefined;
  runningSummary: IRunningSessionSummary | undefined;
  challenges: IChallengeWordSummary[];
  gameviz: ISeries[] | undefined;
  nextRoundStats: IPlayerRoundStat | undefined;
  title: string;
  hand: ICard[];
  pickup: ICard | undefined;
  additionalCard: ICard | undefined;
  hasDrawn: boolean;
  waitingOn: string | undefined;
  outPlayers: string[];
  prompt: string;
  chat: IChat | undefined;
  profile: IProfile | undefined;
  reset: boolean;
  drawCardFromDeck: () => void;
  pickupCardFromPile: () => void;
  play: (play: IPlayRequest) => void;
  confirmRound: () => void;
  confirmGame: () => void;
  nextGame: () => void;
  challengeWord: (word: ICard[], susPlayer: string, round: number) => void;
  acknowledgeChallenge: (id: string, isWord: boolean) => void;
  cancelChallenge: (id: string) => void;
  dismissChallenge: (id: string) => void;
  sendChat: (message: string) => void;
  hardRefreshGame: () => void;
};

const Game: React.FC<GameProps> = ({
  turnId,
  round,
  isPlayersTurn,
  isGameOver,
  isGameFullyConfirmed,
  isGamePlayerConfirmed,
  isRoundOver,
  isRoundPlayerConfirmed,
  isRoundFullyConfirmed,
  isRoundConfirmable,
  avatars,
  gameSummary,
  roundScores,
  gameResult,
  runningSummary,
  challenges,
  roundSummaries,
  gameviz,
  nextRoundStats,
  title,
  hand,
  canPass,
  isOut,
  additionalCard,
  hasDrawn,
  pickup,
  waitingOn,
  outPlayers,
  prompt,
  chat,
  profile,
  reset,
  drawCardFromDeck,
  pickupCardFromPile,
  play,
  confirmRound,
  confirmGame,
  nextGame,
  challengeWord,
  acknowledgeChallenge,
  cancelChallenge,
  dismissChallenge,
  sendChat,
  hardRefreshGame,
}) => {
  const confirmPlay = (turn: IPlayRequest) => {
    setInfoMessage("");
    play(turn);
  };

  const [showSummary, setShowSummary] = useState((isGameOver || isRoundOver) && gameSummary.totals.length > 1);
  const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);

  const x = _.maxBy(roundScores, (x) => x.words.length)?.words.length;
  const isSomebodyOut = (x || 0) > 0;

  useEffect(() => {
    setInfoMessage("");
    setShowSummary(false);
  }, [reset]);

  useEffect(() => { }, [infoMessage]);

  useEffect(() => {
    setInfoMessage(prompt);
  }, [title, prompt]);


  const accent = (x: number, y: number) => {
    if (x < y) return '#ffae03';
    if (x === y) return '#e9190f';
    return 'transparent';
  };
  const borderAccent = (x: number, y: number) => {
    if (x <= y) return '1px solid #081400';
    return '0px solid #081400';
  };

  const hasUnresolvedChallenges = () => {
    return ((challenges || []).length > 0 && (challenges || []).filter((x) => !x.isResolved).length > 0);
  };

  const hasUndismissedChallenges = () => {
    return (challenges || []).length > 0 && (challenges || []).filter((x) => !x.isDismissed).length > 0;
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #081400",
        background: "transparent",
      }}
    >

      <Grid container item xs={12} style={{ maxHeight: "10px", minHeight: "10px" }}>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(3, round), background: accent(3, round) }}>  </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(4, round), background: accent(4, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(5, round), background: accent(5, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(6, round), background: accent(6, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(7, round), background: accent(7, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(8, round), background: accent(8, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(9, round), background: accent(9, round) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "12.5%", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(10, round), background: accent(10, round) }}> </Grid>
        </Grid>
      </Grid>

      {infoMessage && (
        <Grid display="flex" justifyContent="center" container item xs={12} >
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#6D597A",
            border: "1px solid #081400",
            borderRadius: "4px",
            marginTop: "2px",
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "#F9E6DD",
                margin: "4px",
              }}
            >
              {infoMessage}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!((isGameOver && !isGameFullyConfirmed)) && (
        <Grid container item xs={12} justifyContent="center" style={{ marginBottom: '0px' }}>
          <Grid container item xs={0} justifyContent="center"></Grid>
          <Grid container item xs={12} style={{ marginTop: '8px' }}>
            <Grid container item>
              <Avatars
                avatars={avatars}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {((!isPlayersTurn && !isRoundConfirmable) ||
        (isGameOver && isGamePlayerConfirmed)) && (
          <Grid
            container
            item
            justifyContent={"center"}
            xs={12}
            style={{
              marginTop: "8px",
              marginBottom: "0px",
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            <Button
              style={{
                background: "#235789",
                color: "#f4f3ee",
                width: "100%",
                height: "38.5px",
                border: "1px solid #081400",
              }}
              onClick={nextGame}
            >
              go to next game
            </Button>
          </Grid>
        )}

      {isGameOver && !isGamePlayerConfirmed && (
        <Grid item xs={12} display="flex" justifyContent="center"
          style={{
            marginTop: "8px",
            marginBottom: "0px",
            marginLeft: "12px",
            marginRight: "12px",
          }}>
          <Button
            style={{
              background: "#235789",
              color: "#f4f3ee",
              width: "100%",
              border: "1px solid #081400",
              height: "38.5px",
            }}
            onClick={confirmGame}
          >
            confirm game
          </Button>
        </Grid>
      )}

      {isOut &&
        ((!isRoundPlayerConfirmed && isRoundConfirmable)) && (
          <Grid item xs={12} display="flex" justifyContent="center"
            style={{
              marginTop: "8px",
              marginBottom: "0px",
              marginLeft: "12px",
              marginRight: "12px",
            }}>
            <Button
              disabled={hasUnresolvedChallenges()}
              style={{
                background: !hasUnresolvedChallenges() ? "#235789" : "#a1a1a1",
                color: "#f4f3ee",
                width: "100%",
                border: "1px solid #081400",
                height: "38.5px",
              }}
              onClick={confirmRound}
            >
              {round === 10 ? "end 10-zies" : "play " + (round + 1) + "-zies"}
            </Button>
          </Grid>
        )}

      {!isGameOver && nextRoundStats && (
        <Grid item xs={12} style={{
          background: "transparent",
          border: "1.5px solid #081400",
          borderRadius: "4px",
          marginTop: "8px",
          marginLeft: "12px",
          marginRight: "12px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "white",
                margin: "8px",
              }}
            >
              your stats for next round
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{ marginTop: "8px", marginBottom: "8px", width: "100%" }}>
            <Grid display="flex" justifyContent="center" container item xs={12} style={{ marginBottom: '8px' }} >

              <Grid display="flex" justifyContent="center" container item xs={4}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    width: "100%",
                    color: "#0f1919",
                    margin: "4px",
                  }}
                >
                  win %
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center" container item xs={4}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    color: "purple",
                    width: "100%",
                    margin: "4px",
                  }}
                >
                  longbone %
                </Typography>
              </Grid>
              <Grid display="flex" justifyContent="center" container item xs={4}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    color: "darkorange",
                    width: "100%",
                    margin: "4px",
                  }}
                >
                  multibone %
                </Typography>
              </Grid>
            </Grid>

            <StatsRow title={`win rate`} wins={nextRoundStats.wins} losses={nextRoundStats.losses} lbs={nextRoundStats.lbs} mbs={nextRoundStats.mbs} />
          </Grid>
        </Grid>
      )}

      {!isGameOver && (hasUnresolvedChallenges() || hasUndismissedChallenges()) && (
        <Grid item xs={12} style={{
          background: "transparent",
          border: "1.5px solid #081400",
          borderRadius: "4px",
          marginTop: "8px",
          marginLeft: "12px",
          marginRight: "12px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "white",
                margin: "8px",
              }}
            >
              challenge
            </Typography>
          </Grid>

          <Grid item xs={12} style={{
            padding: "0px",
            marginTop: "0px",

          }}>
            <Challenge
              challenges={challenges}
              acknowledgeChallenge={acknowledgeChallenge}
              cancelChallenge={cancelChallenge}
              dismissChallenge={dismissChallenge}
            />
          </Grid>
        </Grid>
      )}

      {gameResult && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginTop: "8px",
          border: "1.5px solid #081400",
          marginLeft: "12px",
          marginRight: "12px",
          borderRadius: "4px"
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "white",
                margin: "8px",
              }}
            >
              game over
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "0px", marginBottom: '0px', marginTop: '12px' }}>
            <GameResult gameResult={gameResult} />

            {runningSummary && runningSummary.currentStreakAmount > 1 && (
              <Grid
                container
                item xs={12}
                justifyContent={"center"}
                style={{
                  marginTop: "4px",
                  marginBottom: "4px",
                }}
              >
                <Typography
                  align="left"
                  variant="h2"
                  style={{
                    color: "#245501",
                    fontWeight: 500,
                  }}
                >
                  {runningSummary.currentStreakPlayer} has {runningSummary.currentStreakAmount} wins in a row
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {(isGameOver || isRoundOver || !isPlayersTurn || showSummary) && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginTop: "8px",
          border: "1.5px solid #081400",
          marginLeft: "12px",
          marginRight: "12px",
          borderRadius: "4px",
          background: "#fafafa"
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "white",
                margin: "8px",
              }}
            >
              game summary
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "0px", marginBottom: '0px', marginTop: '12px' }}>
            <GameScore
              summary={gameSummary}
              isGameOver={isGameOver} />
          </Grid>
          {(gameviz) && (
            <Grid
              container
              item
              xs={12}
              style={{
                background: "transparent",
                border: "0px solid black",
                padding: "0px",
                marginTop: '4px',
                marginBottom: '4px'
              }}
            >
              <GameViz series={gameviz} />
            </Grid>
          )}
          <Grid item xs={12} style={{ padding: "0px", marginBottom: '12px', marginTop: '12px' }}>
            <GameSummary
              summary={gameSummary}
            />
          </Grid>

          {roundSummaries && roundSummaries.length > 0 && (
            <Grid display="flex" justifyContent="center" container item xs={12} style={{
              background: "#42273B",
              borderTop: "1px solid #081400",
              borderBottom: "1px solid #081400",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px"
            }}>
              <Typography
                align="center"
                variant="h1"
                style={{
                  width: "100%",
                  color: "white",
                  margin: "8px",
                }}
              >
                words played
              </Typography>
            </Grid>
          )}

          {roundSummaries && roundSummaries.map((rs: IPlayerRoundScore[], i) => (
            <Grid
              container
              item
              xs={12}
              key={"k-rsssh" + i}
              style={{
                background: "transparent",
                marginTop: "2px",
                marginBottom: "2px",
              }}>

              <Grid item xs={12} style={{
                background: "transparent",

              }}>
                <EndGameRoundSummary
                  roundNumber={i + 3}
                  scores={rs}
                  isGameOver={isGameOver}
                  challengeWord={challengeWord}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {!isGameOver && isSomebodyOut && roundScores.length > 0 && (
        <Grid item xs={12} style={{
          background: "transparent",
          marginTop: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
        }}>
          <RoundSummary
            roundNumber={round}
            scores={roundScores}
            isRoundOver={isRoundOver}
            challengeWord={challengeWord}
          />
        </Grid>
      )}

      {!isOut && (
        <Grid item xs={12} style={{
          marginRight: "12px",
          marginLeft: "12px",
          marginTop: "12px",
          border: "1.5px solid #081400",
          background: "#fafafa",
          borderRadius: "4px"
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
                color: "white",
                margin: "8px",
              }}
            >
              {isPlayersTurn ? 'your turn' : 'not your turn'}
            </Typography>
          </Grid>
          <Play
            turnId={turnId}
            roundAmount={round}
            cards={hand}
            isPlayersTurn={isPlayersTurn}
            pickup={pickup}
            canDraw={!hasDrawn}
            additionalCard={additionalCard}
            canPass={canPass}
            emptyState={isOut}
            reset={reset}
            drawCardFromDeck={drawCardFromDeck}
            pickupCardFromPile={pickupCardFromPile}
            confirmPlay={confirmPlay}
            setMessage={setInfoMessage}
          />
        </Grid>
      )}

      {chat && profile && (
        <Grid item xs={12} style={{
          marginTop: "12px",
          marginRight: "12px",
          marginLeft: "12px",
          border: "1.5px solid #081400",
          borderRadius: "4px",
          background: "#fafafa"
        }}>

          <Grid display="flex" justifyContent="center" container item xs={12} style={{
            background: "#42273B",
            borderBottom: "1px solid #081400",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                color: "white",
                margin: "8px",
              }}
            >
              chat
            </Typography>
          </Grid>

          <Chat history={chat.history} chatChips={profile?.chatChips || []} chat={sendChat} />
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        style={{
          justifyContent: "center",
          marginTop: "8px",
          marginBottom: "8px",
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <Button
          style={{
            background: "#e63946",
            width: "100%",
            height: "38.5px",
            textTransform: "none",
            alignItems: "center",
            border: "1px solid #081400",
          }}
          onClick={(e) => hardRefreshGame()}
        >
          <Grid
            container
            item
            xs={12}
            style={{
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              variant="h5"
              style={{
                color: "#f4f3ee",
              }}
            >
              report problem with game
            </Typography>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Game;
