import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";

import {
  IAvatar,
  ICard,
  IPlayerRoundScore,
  IPlayRequest,
} from "../../Types/types";
import Play from "../Play";
import _ from "lodash";

import RoundSummary from "../RoundSummary";
import Avatars from "../Avatars";
import { makeId } from "../../Types/util";

type TutorialStep5Props = {
  playerName: string;
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep5: React.FC<TutorialStep5Props> = ({
  stepNumber,
  playerName,
  isLastStep,
  progressTutorial,
}) => {

  const [toggle, setToggle] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string | undefined>('4-zies');
  const [h1Message, setH1tMessage] = useState<string | undefined>(undefined);
  const [h2Message, setH2Message] = useState<string | undefined>(undefined);
  const [avatars, setAvatars] = useState<IAvatar[]>([
    {
      initial: "O",
      isTurn: false,
      isPassed: false,
      isOut: true,
    }, {
      initial: playerName[0].toUpperCase(),
      isTurn: true,
      isPassed: false,
      isOut: false,
    }
  ]);
  const [hand, setHand] = useState<ICard[]>([
    { id: makeId(), letters: "K", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
    { id: makeId(), letters: "Y", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
    { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
    { id: makeId(), letters: "R", value: 4, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
  ]);
  const [pickup, setPickup] = useState<ICard | undefined>({ id: makeId(), letters: "S", value: 3, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },);
  const [additionalCard, setAdditionalCard] = useState<ICard | undefined>(undefined);
  const [roundScores, setRoundScores] = useState<IPlayerRoundScore[]>(
    [
      {
        player: 'oppponent',
        score: 16,
        duds: [],
        words: [{
          cards: [
            { id: makeId(), letters: "B", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "O", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "E", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
          ],
          word: "LONG",
          validWord: true,
          canChallenge: false,
        }],
        lb: true,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ]);
  const [canDraw, setCanDraw] = useState<boolean>(true);
  const [isOut, setIsOut] = useState<boolean>(false);

  const reset = () => {
    setToggle(!toggle);
    setAvatars([
      {
        initial: "O",
        isTurn: false,
        isPassed: false,
        isOut: true,
      }, {
        initial: playerName[0].toUpperCase(),
        isTurn: true,
        isPassed: false,
        isOut: false,
      }]);
    setHand([
      { id: makeId(), letters: "K", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "Y", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "R", value: 4, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
    ],)
    setPickup({ id: makeId(), letters: "S", value: 3, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false });
    setAdditionalCard(undefined);
    setCanProgress(false);
    setIsOut(false);
    setRoundScores([
      {
        player: 'oppponent',
        score: 16,
        duds: [],
        words: [{
          cards: [
            { id: makeId(), letters: "B", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "O", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            { id: makeId(), letters: "E", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
          ],
          word: "BONE",
          validWord: true,
          canChallenge: false,
        }],
        lb: false,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ]);
    setCanDraw(true);
  };

  const drawCardFromDeck = () => {
    const cards: ICard[] = [{ id: makeId(), letters: 'A', value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }, { id: makeId(), letters: 'E', value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }, { id: makeId(), letters: 'I', value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }, { id: makeId(), letters: 'O', value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }, { id: makeId(), letters: 'U', value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }];
    const randomIndex = Math.floor(Math.random() * cards.length);
    const ac = cards[randomIndex];
    setAdditionalCard(ac);
    setPickup(undefined);
    setHand([
      { id: makeId(), letters: "K", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "Y", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "R", value: 4, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      ac
    ]);
  }

  const pickupCardFromPile = () => {
    setAdditionalCard(pickup);
    setPickup(undefined);
    setHand([
      { id: makeId(), letters: "K", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "Y", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "R", value: 4, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
      { id: makeId(), letters: "S", value: 3, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false }
    ]);
  };

  const confirmPlay = (turn: IPlayRequest) => {
    if (turn.guesses.length === 0 && turn.duds.length === 4) {
      const rs = [
        {
          player: 'oppponent',
          score: 16,
          duds: [],
          words: [{
            cards: [
              { id: makeId(), letters: "B", value: 7, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
              { id: makeId(), letters: "O", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
              { id: makeId(), letters: "N", value: 5, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
              { id: makeId(), letters: "E", value: 2, isSelectedForDiscard: false, isPlayed: false, isCurrectGuess: false },
            ],
            word: "BONE",
            validWord: true,
            canChallenge: false,
          }],
          lb: true,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
        {
          player: playerName,
          score: 0,
          duds: turn.duds,
          words: [],
          lb: false,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
      ]
        ;
      setAvatars([{
        initial: "O",
        isTurn: false,
        isPassed: false,
        isOut: true,
      }, {
        initial: playerName[0].toUpperCase(),
        isTurn: false,
        isPassed: false,
        isOut: true,
      }]);
      setRoundScores(rs);
      setCanProgress(true);
      setCanDraw(false);
      setIsOut(true);
    }
  };

  const confirmRound = () => {
  };

  const nextGame = () => {
  };

  const challengeWord = () => {
  };


  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 5: when you cannot play all your cards
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            to complete the lesson, GO OUT with no cards played
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            your opponent has played BONE and you cannot vaake a valid word with your cards
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you must go out
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you can GO OUT without playing any cards
          </Typography>
        </Grid>
      </Grid>

      {h1Message && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "black",
              margin: "4px",
            }}
          >
            {h1Message}
          </Typography>
        </Grid>
      )}

      {roundScores && (
        <Grid item xs={12} style={{ padding: "0px", marginBottom: '4px', marginTop: '8px' }}>
          <Avatars
            avatars={avatars}
          />
        </Grid>
      )}

      <Grid item xs={12} style={{ padding: "5px" }}>
        <RoundSummary
          roundNumber={5}
          scores={roundScores}
          isRoundOver={true}
          challengeWord={challengeWord}
        />
      </Grid>


      {!isOut && (
        <Grid item xs={12} style={{ margin: "4px", border: "1px solid #0f1919", borderRadius: "4px" }}>
          <Play
            turnId={'tutorial'}
            roundAmount={7}
            cards={hand}
            isPlayersTurn={true}
            pickup={pickup}
            additionalCard={additionalCard}
            canPass={false}
            canDraw={canDraw}
            emptyState={false}
            reset={toggle}
            drawCardFromDeck={drawCardFromDeck}
            pickupCardFromPile={pickupCardFromPile}
            confirmPlay={confirmPlay}
            setMessage={setH2Message}
          />
        </Grid>
      )}
      {!canProgress && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              you have tried to play an invalid word
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* 
      {!canProgress && !word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You have not gone out, you have passed your turn.
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
          </Grid>
        </Grid>
      )} */}

      {canProgress && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item style={{ marginTop: "40px" }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Well done! You have gone out without playing any cards
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              you will lose 14 points from you score for this round
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              your overall game score can not go below 0 points
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              In lesson 6, you will discover you choices when your opponent plays a dosgy word
            </Typography>

          </Grid>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            color: "white",
            border: "1px solid #0f1919",
            height: "38.5px",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginRight: "4px",
          }}
          onClick={reset}
        >
          restart lesson {stepNumber}
        </Button>
        <Button
          disabled={!canProgress}
          style={{
            background: canProgress ? "green" : "grey",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px",
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep5;
