
import React, { FC } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const Box = styled.div`
backgroundColor: "grey",
width: "100px",
height: "100px",
  border: 1px solid black;
`;

export interface DndSpaceProps {
    index: number,
    children: any,
    moveCard: (from: number, to: number) => void

}

export const DndSpace: FC<DndSpaceProps> = ({
    index,
    children,
    moveCard
}: DndSpaceProps) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: "card",
        canDrop: () => true,
        drop: (item: { id: number }, monitor) => {
            moveCard(item.id, index)
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    return (
        <div
            ref={drop}
            style={{
                position: "relative",
                width: '100%',
                height: '100%',
            }}
        >
            <Box>{children}</Box>
        </div>
    );
};