import React from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import {
  IPlayerRoundScore,
  ICard,
} from "../../Types/types";
import _ from "lodash";
import RoundSummaryPlayerSummary from "../RoundSummaryPlayerSummary";

type RoundSummaryProps = {
  roundNumber: number;
  scores: IPlayerRoundScore[];
  isRoundOver: boolean;
  challengeWord: (word: ICard[], susPlayer: string, round: number) => void;
};


const RoundSummary: React.FC<RoundSummaryProps> = ({
  roundNumber,
  scores,
  isRoundOver,
  challengeWord,
}: RoundSummaryProps) => {

  const x = _.maxBy(scores, (x) => x.words.length)?.words.length;
  const isSomebodyOut = (x || 0) > 0;

  const tryChallengeWord = (word: ICard[], susPlayer: string) => {
    challengeWord(word, susPlayer, roundNumber);
  }

  return (
    <Grid
      display="flex"
      justifyContent="center"
      container
      item
      style={{
        background: "#fafafa",
        border: "1.5px solid #0f1919",
        borderRadius: "4px"
      }}
    >
      <Grid display="flex" justifyContent="center" container item xs={12} style={{
        background: "#42273B",
        borderBottom: "1px solid #0f1919",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px"
      }}>
        <Typography
          align="center"
          variant="h1"
          style={{
            width: "100%",
            color: "white",
            margin: "8px",
          }}
        >
          {roundNumber}-zies summary
        </Typography>
      </Grid>

      {isSomebodyOut && scores.length > 0 && (
        <Grid container item xs={12}
          style={{
            marginTop: "14px",
            marginBottom: "8px",
          }}
        >
          {scores
            .filter((x) => x.words.length > 0 || x.duds.length > 0)
            .map((prs: IPlayerRoundScore, i) => (
              <Grid
                key={"k-rasss" + i}
                container
                item
                xs={12}
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                  minHeight: "37px",
                  padding: "0px",
                  margin: "4px",
                }}
              >
                <RoundSummaryPlayerSummary
                  prs={prs}
                  isRoundOver={isRoundOver}
                  challengeWord={tryChallengeWord} />
              </Grid>
            ))}
        </Grid>
      )}

    </Grid>

  );
};

export default RoundSummary;
