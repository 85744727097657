
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import DndCard from "./DndCard";
import { ICard, ISpace } from "../../Types/types";
import { Box, Grid } from "@mui/material";
import { forEach } from "lodash";
import _ from "lodash";
import { DndSpace } from "./DndSpace";

export interface DndHandProps {
  spaces: ISpace[],
  isPlayersTurn: boolean,
  play: (id: number, card: ICard) => void,
  moveCard: (from: number, to: number) => void,
}
const DndHandStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
}
export const DndHand: FC<DndHandProps> = ({
  spaces,
  isPlayersTurn,
  play,
  moveCard
}) => {

  function renderSquare(space: ISpace, i: number) {
    return (
      <div
        key={"k-khc" + i}
        style={{
          background: "transparent",
          width: "43px",
          height: "70px",
          paddingTop: "12px",
          marginLeft: "4px",
          marginRight: "4px",
        }}
      >
        <DndSpace index={space.id} moveCard={moveCard}>
          {space.card && space.isInPlay && (
            <DndCard index={i} space={space} play={play} />
          )}
          {!(space.card && space.isInPlay) && (
            <Box
              style={{
                background: 'transparent',
                width: "43px",
                height: "70px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "0px",
                minWidth: 0,
                minHeight: 0,
                boxShadow: '0 0 0px #171C21',
                textShadow: "0 2px 3px white",
              }}
            >
            </Box>
          )}
        </DndSpace>
      </div>
    );
  }
  const squares: JSX.Element[] = []
  forEach(spaces, (space: ISpace, i) => {
    squares.push(renderSquare(space, i))
  }
  )
  return <Grid container item xs={12} justifyContent="center"
    style={DndHandStyle}>{squares}</Grid>
};

export default DndHand; 