import React, { useRef, useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ICard, IHand, ISpace } from "../../Types/types";
import Card from "../Card";
import DrawCard from "../DrawCard";
import { default as TouchBackend } from 'react-dnd-touch-backend'; 
import Space from "../Space";
import XHand from "./DndHand";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

export type HandProps = {
  spaces: ISpace[];
  guessingCards: ICard[];
  isPlayersTurn: boolean;
  canDraw: boolean;
  confirmed: boolean;
  pickup: ICard | undefined;
  emptyState: boolean;
  reset: boolean;
  playCard: (id: number, card: ICard) => void;
  callDrawCardFromDeck: () => void;
  callPickupCardFromPile: () => void;
  shuffleCards: () => void;
  playWord: () => void;
  moveCard: (from: number, to: number) => void,
};
export const Hand: React.FC<HandProps> = ({
  spaces,
  guessingCards,
  isPlayersTurn,
  canDraw,
  confirmed,
  pickup,
  emptyState,
  reset,
  playCard,
  callDrawCardFromDeck,
  callPickupCardFromPile,
  shuffleCards,
  playWord,
  moveCard,
}: HandProps) => {

  const [isDrawn, setIsDrawn] = useState<boolean>(false);

  const doCallPickupCardFromPile = () => {
    setIsDrawn(true);
    callPickupCardFromPile();
  };
  const doCallPickupCardFromDeck = () => {
    setIsDrawn(true);
    callDrawCardFromDeck();
  };

  useEffect(() => {
    setIsDrawn(false);
  }, [reset]);

  const calculateWidth = (): number => {
    if (spaces.length <= 6) return 12;
    if (spaces.length <= 8) return 8;
    if (spaces.length <= 10) return 9;
    if (spaces.length <= 12) return 10;
    return 12;
  };

  const ltrs = (emptyState) ? '' : guessingCards.map((c) => c.letters).join("");
  const msg = (emptyState ? '' : (ltrs.length <= 1))
    ? 'start spelling a word ' + (ltrs.length === 0 ? '' : '(' + ltrs + "...)")
    : 'i am happy with ' + ltrs;
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "0px",
        background: "transparent",
        border: "0px solid #081400",
      }}
    >
      <Grid
        container
        style={{
          background: "transparent",
        }}
      >
        <Grid container item xs={12} justifyContent="center"
          style={{
            background: '#FBFFB9',
            marginBottom: "0px",
          }}>

          <Grid
            container
            item
            xs={12}
            style={{
              justifyContent: "center",
              width: "100%",
              margin: "0px",
            }}
          >
            <Button
              disabled={guessingCards.length < 2}
              style={{
                background: guessingCards.length < 2 ? "#7bebf3" : "#a5c34f",
                color: guessingCards.length < 2 ? "#1f587a" : "#081400",
                width: "100%",
                height: "38.5px",
                borderTop: "1px solid #081400",
                borderBottom: "1px solid #081400",
                paddingTop: "0px",
                paddingBottom: "2px",
                margin: "0px",
                borderRadius: "0px",
                textTransform: "none",
              }}
              onClick={(e) => playWord()}
            >
              {msg}
            </Button>
          </Grid>

          {spaces.filter(s => s.card).length > 0 && (
            <Grid container item xs={12} justifyContent="center"
              style={{
                // paddingTop: "12px",
                marginBottom: "12px",
              }}>

              <Grid container item xs={calculateWidth()} justifyContent="center"
                style={{
                  background: "transparent",
                  width: "100%",
                }}
              >
                <DndProvider options={HTML5toTouch}>
                  <XHand spaces={spaces} isPlayersTurn={isPlayersTurn} play={playCard} moveCard={moveCard} />
                </DndProvider>

                

                {/* <DndProvider backend={HTML5Backend}>
                  <XHand spaces={spaces} isPlayersTurn={isPlayersTurn} play={playCard} moveCard={moveCard} />
                </DndProvider> */}
              </Grid>
            </Grid>
          )}
        </Grid>

        {spaces.length === 0 && (
          <Grid item xs={12} style={{ background: "transparent", height: '114px' }}>
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          style={{
            justifyContent: "center",
            borderTop: "1px solid #081400",
            borderBottom: "0px solid #081400",
            width: "100%",
            margin: "0px",
          }}
        >
          <Button
            style={{
              background: "#a5c34f",
              color: "#081400",
              width: "100%",
              height: "38.5px",
              borderBottom: "1px solid #081400",
              paddingTop: "2px",
              paddingBottom: "2px",
              padding: "0px",
              margin: "0px",
              borderRadius: "0px",
              textTransform: "none",
            }}
            onClick={(e) => shuffleCards()}
          >
            {emptyState ? '' : 'shuffle'}
          </Button>
        </Grid>

      </Grid>

      {isPlayersTurn && canDraw && !isDrawn && !confirmed && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{
            background: "#ffed4d",
            paddingTop: "16px",
            paddingBottom: "16px",
            //marginTop: "16px",
          }}
        >
          <Grid container item xs={6} style={{ paddingRight: "4px" }}>
            {pickup && (
              <Grid container item justifyContent="right" direction={"row"}>
                <Card card={pickup} play={doCallPickupCardFromPile} isSmall={true} isInPlay={true} />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={6} style={{ paddingLeft: "4px" }}>
            <Grid container item style={{ margin: "0px" }}>
              <Grid
                container
                item
                direction={"row"}
                style={{ display: "flex", alignItems: "end", margin: "0px" }}
              >
                <DrawCard drawCard={doCallPickupCardFromDeck} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
      }
    </Grid>
  )
};

export default Hand;
