import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { IGameResult, IGameSummary, IGameSummaryTotal } from "../../Types/types";
import _ from "lodash";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';


type GameResultProps = {
  gameResult: IGameResult;
};

const GameResult: React.FC<GameResultProps> = ({
  gameResult,
}: GameResultProps) => {


  return (
    <Grid
      display="flex"
      justifyContent="center"
      container
      item
      style={{
        background: "white",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      {/* <Grid container item xs={3} justifyContent="center">
        <Typography align="center" variant="h2">
          game Result
        </Typography>
      </Grid> */}


      {gameResult.winner && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginBottom: "12px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={12} style={{
          }}>
            <EmojiEventsIcon />
          </Grid>

          <Grid display="flex" justifyContent="center" container item xs={12} style={{
          }}>
            <Typography
              align="center"
              variant="h1"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.winner}
            </Typography>
          </Grid>
        </Grid>
      )}

      {gameResult.longestWord && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginBottom: "8px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={5} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              longest word
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={4} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.longestWord.player}
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={1} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.longestWord.value}
            </Typography>
          </Grid>
        </Grid>
      )}

      {gameResult.mostLongBones && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginBottom: "8px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={5} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              most long bones
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={4} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.mostLongBones.player}
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={1} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.mostLongBones.value}
            </Typography>
          </Grid>
        </Grid>
      )}

      {gameResult.mostMultiBones && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{
          marginBottom: "8px",
        }}>
          <Grid display="flex" justifyContent="center" container item xs={5} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              most multi bones
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={4} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.mostMultiBones.player}
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={1} style={{
          }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
              }}
            >
              {gameResult.mostMultiBones.value}
            </Typography>
          </Grid>
        </Grid>
      )}

    </Grid>
  );
};

export default GameResult;
